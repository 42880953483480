import {
  ApplicationInsights,
  ITelemetryItem,
} from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { EnvironmentUtils } from "brainsupporter-core/lib/util/EnvironmentUtils";
import { WebCompositionRoot } from "../WebCompositionRoot";
import { SingleRepository } from "brainsupporter-core/lib/repository/SingleRepository";
import { Topics } from "brainsupporter-core/lib/domain/pubsub/Topics";
import { IndexedDbRepository } from "../repository/IndexedDbRepository";
import { UserConfigModel } from "brainsupporter-core/lib/domain/domainModels/UserConfigModel";
import { BrowserOrNode } from "brainsupporter-core/lib/util/BrowserOrNode";

// Example from https://github.com/microsoft/applicationinsights-react-js/blob/main/sample/applicationinsights-react-sample/src/ApplicationInsightsService.tsx

/* istanbul ignore file */ // Coverage can be improved

export class ApplicationInsightsService {
  public static reactPlugin = new ReactPlugin();
  public static appInsights: ApplicationInsights;
  public static enableLogging: boolean = false;

  public static initializeTelemetry(
    accountFolder: string,
    root: WebCompositionRoot,
  ) {
    root.Notifier.subscribe(Topics.CONFIG_CHANGED, async () => {
      await ApplicationInsightsService.updateEnableConfig();
    });

    this.appInsights.addTelemetryInitializer((env: ITelemetryItem) => {
      env.data = env.data || {};
      env.data["full-version"] = EnvironmentUtils.getFullVersion();
      env.data["accountFolder"] = accountFolder;
    });
  }

  public static async initializeDuringJSLoad() {
    if (BrowserOrNode.isNode()) {
      // Skip during unittests, only load in browser
      return;
    }

    const connectionString = this.getConnectionString();

    this.appInsights = new ApplicationInsights({
      config: {
        connectionString: connectionString,
        extensions: [ApplicationInsightsService.reactPlugin],
        enableAutoRouteTracking: true,
        disableAjaxTracking: false,
        autoTrackPageVisitTime: true,
        enableCorsCorrelation: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        enableAjaxPerfTracking: true,
        isCookieUseDisabled: true,

        //disableTelemetry: !this.enableLogging,
      },
    });

    this.appInsights.addTelemetryInitializer(
      ApplicationInsightsService.filteringFunction,
    );

    this.appInsights.loadAppInsights(); // Always load and use filteringFunction to turn telemetry off. Loading later doens't work

    await ApplicationInsightsService.updateEnableConfig();
  }

  private static async updateEnableConfig() {
    // Get the config value from local storage cache, so we also have something before the application started anmd root is created
    const cachedConfigRepo = new SingleRepository(
      new IndexedDbRepository<UserConfigModel>("UserConfigRepository"),
    );
    const config = (await cachedConfigRepo.tryGet()) || new UserConfigModel();
    this.enableLogging = config?.enableLogging ?? false;
  }

  private static filteringFunction(): boolean {
    // TODO: Move enableLogging to a class and update on config updates
    return ApplicationInsightsService.enableLogging; // false == Do not send telemetry
  }

  public static getConnectionString() {
    if (EnvironmentUtils.isDev()) {
      return "InstrumentationKey=c3579b62-9d22-4f11-897c-c22e308d2452;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/;ApplicationId=c77edd7b-142a-49a7-8e37-3e2171039fb9";
    }

    if (EnvironmentUtils.isCanary() || EnvironmentUtils.isProduction()) {
      return "InstrumentationKey=b108dfcd-1c75-47a2-bfdf-d4e3b4ba4d0d;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/;ApplicationId=52a57e89-cd70-4bdb-9721-738a9eae6635";
    }
  }
}

void ApplicationInsightsService.initializeDuringJSLoad();
