import { epoch, timestamp } from "../domain/Types";

export class DateTimeUtils {
  public static readonly dayInSeconds = 86400;
  public static readonly monthInSeconds = DateTimeUtils.dayInSeconds * 31;

  /* istanbul ignore next */
  public static zeroTimestamp: timestamp = DateTimeUtils.isBrowser()
    ? Number(0)
    : BigInt(0);

  public static getEpoch(): epoch {
    return Math.round(new Date().getTime() / 1000);
  }

  public static getCurrentTimestamp(): timestamp {
    /* istanbul ignore next */
    if (DateTimeUtils.isBrowser()) {
      return window.performance.now(); // Use this in the browser. Date.now() is not precise enough
    }
    return process.hrtime.bigint();
  }

  public static getEpochOffSetFromNow(seconds: number): epoch {
    const now = DateTimeUtils.getEpoch(); // as epoch;
    return now + seconds;
  }

  /* istanbul ignore next */
  // TODO: Move to BrowserOrNode
  private static isBrowser() {
    return (
      typeof window !== "undefined" && typeof window.document !== "undefined"
    );
  }
}

// Patch BigInt to support toJSON globally.
// See also https://github.com/GoogleChromeLabs/jsbi/issues/30
/* eslint-disable @typescript-eslint/no-explicit-any */
(BigInt.prototype as any).toJSON = function () {
  return this.toString();
};
