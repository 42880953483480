import { BrowserOrNode } from "./BrowserOrNode";

export class ConcurrencyUtils {
  // Wait milliseconds
  public static wait(milliseconds: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, milliseconds);
    });
  }

  /* istanbul ignore next */ // Not used in integration
  public static runWhenIdle(
    callback: () => Promise<void>,
    timeout: number = 10000,
  ): Promise<void> {
    return new Promise<void>(function (resolve, reject) {
      if (
        (BrowserOrNode.isBrowser() || BrowserOrNode.isWebWorker()) &&
        "requestIdleCallback" in window
      ) {
        // Use requestIdleCallback if available
        requestIdleCallback(
          ConcurrencyUtils.promisifyCallback(callback, resolve, reject),
          { timeout: timeout },
        );
      } else {
        // Fallback to setTimeout with a short delay
        setTimeout(
          ConcurrencyUtils.promisifyCallback(callback, resolve, reject),
          timeout,
        );
      }
    });
  }

  /* istanbul ignore next */ // Not used in integration
  /* eslint-disable @typescript-eslint/no-explicit-any */
  private static promisifyCallback(
    callback: () => Promise<void>,
    resolve: (value: void | PromiseLike<void>) => void,
    reject: (reason?: any) => void,
  ): IdleRequestCallback {
    return async () => {
      try {
        await callback();
        resolve();
      } catch (error) {
        reject(error);
      }
    };
  }
}
