import Dexie, { type EntityTable } from "dexie";

import { AbstractRepository } from "brainsupporter-core/lib/repository/AbstractRepository";
import { DateTimeUtils } from "brainsupporter-core/lib/util/DateTimeUtils";
import { IRepository } from "brainsupporter-core/lib//domain/IRepository";
import { timestamp } from "brainsupporter-core/lib/domain/Types";
import { IIdentifiable } from "brainsupporter-core/lib/domain/domainModels/IIdentifiable";
import { IValidate } from "brainsupporter-core/lib/domain/domainModels/IValidate";
import { DependencyInjectionUtils } from "brainsupporter-core/lib/util/DependencyInjectionUtils";
import { ModelCloner } from "brainsupporter-core/lib/util/ModelCloner";

// TODO: Solve clearing the index db on changing user
/* istanbul ignore next */ // TODO: Write tests
export class IndexedDbRepository<T extends IIdentifiable & IValidate>
  extends AbstractRepository<T>
  implements IRepository<T>
{
  private db!: Dexie;

  constructor(private readonly databaseName: string) {
    super();

    DependencyInjectionUtils.validateDependenciesDefined(arguments);

    this.initializeDB();
    //this.table.mapToClass(T); // TODO: Need this?
  }

  private initializeDB() {
    this.db = new Dexie(this.databaseName) as Dexie & {
      repoTable: EntityTable<T, "uuid">;
      timestampTable: EntityTable<TimestampModel, "id">;
    };

    // Can we ignore it for now by deleting it all and rebuilding?
    // Schema declaration:
    this.db.version(1).stores({
      repoTable: "uuid",
      timestampTable: "id",
    });
  }

  public async getAll(): Promise<readonly T[]> {
    const storedModels = await this.db.table("repoTable").toArray();

    const clonedModels: T[] = [];
    for (const storedModel of storedModels) {
      // TODO: Measure perf. Better to use copyToType copyToTypeNew or CloneToType?
      const model = ModelCloner.cloneToType(storedModel) as T;
      model.validate();
      clonedModels.push(model);
    }

    return Promise.resolve(clonedModels);
  }

  public override async tryGetByUuid(uuid: string): Promise<T | undefined> {
    const model: T | undefined = await this.db.table("repoTable").get(uuid);
    if (model) {
      return ModelCloner.cloneToType(model) as T;
    } else {
      return undefined;
    }
  }

  public async save(model: T): Promise<timestamp> {
    await this.db.table("repoTable").put(model);
    return Promise.resolve(DateTimeUtils.zeroTimestamp);
  }

  public async deleteAll(): Promise<timestamp> {
    await this.db.table("repoTable").clear();
    await this.db.table("timestampTable").clear();
    //this.initializeDB();
    return DateTimeUtils.zeroTimestamp;
  }

  public async getTimestamp(): Promise<timestamp> {
    const timestamp = (await this.db
      .table("timestampTable")
      .get(fixedTimestampID)) as TimestampModel | undefined;

    if (timestamp) {
      return timestamp.timestamp;
    } else {
      return DateTimeUtils.zeroTimestamp;
    }
  }

  public async delete(uuid: string): Promise<timestamp> {
    await this.db.table("repoTable").delete(uuid);
    return this.writeTimestamp();
  }

  public async import(models: readonly T[]): Promise<timestamp> {
    await this.deleteAll();
    await this.db.table("repoTable").bulkPut(models);
    return this.writeTimestamp();
  }

  private async writeTimestamp(): Promise<timestamp> {
    const timestamp = DateTimeUtils.getCurrentTimestamp();
    await this.db.table("timestampTable").put(new TimestampModel(timestamp));
    return timestamp;
  }
}

const fixedTimestampID = 1;
/* istanbul ignore next */ // TODO: Write tests
class TimestampModel {
  public id = fixedTimestampID; // Fixed ID as we only want to store 1 row
  public timestamp: timestamp = DateTimeUtils.zeroTimestamp;

  constructor(timestamp: timestamp) {
    this.timestamp = timestamp;
  }
}
