import { SingleRepository } from "../repository/SingleRepository";
import { BrowserOrNode } from "../util/BrowserOrNode";
import { DependencyInjectionUtils } from "../util/DependencyInjectionUtils";
import { TextUtils } from "../util/TextUtils";
import { ContextModel } from "./domainModels/ContextModel";

export class AutoContextService {
  // TODO: Rename to formatContextWihtoutAdd? and move to ContextService
  public static formatContext(context: string): string {
    return context.replace("@", "").toLocaleLowerCase();
  }

  private currentContextPromise?: Promise<string> = undefined; // Acts as a lock so we only load once and wait for that

  constructor(
    private readonly contextRepository: SingleRepository<ContextModel>,
  ) {
    DependencyInjectionUtils.validateDependenciesDefined(arguments);
  }

  public async getContext(): Promise<string> {
    if (this.currentContextPromise === undefined) {
      this.currentContextPromise = this.initializeContext(); // Will set currentContext from URL in browser before await, so that it completes before any getContext
    }
    return await this.currentContextPromise;
  }

  public async setContext(context: string): Promise<string> {
    const formattedContext = AutoContextService.formatContext(context);
    this.updateContextInQueryParameter(formattedContext);
    this.currentContextPromise = Promise.resolve(formattedContext);

    const storedOrNewModel =
      (await this.contextRepository.tryGet()) || new ContextModel();
    if (storedOrNewModel.context != formattedContext) {
      await this.contextRepository.updateValues(storedOrNewModel, {
        context: formattedContext,
      });
    }

    return formattedContext;
  }

  public async applyAutoContext(contexts: string[]): Promise<string[]> {
    const currentContext = await this.getContext();
    if (contexts.length === 0 && currentContext) {
      return Promise.resolve([currentContext]);
    }
    return contexts;
  }

  private async initializeContext(): Promise<string> {
    let initialContext = undefined;

    /// Try get from the URL in browser. URL should take precedence
    /* istanbul ignore if */ // TODO: Test with mocking window.location.search?
    if (!BrowserOrNode.isNode()) {
      const urlParams = new URLSearchParams(window.location.search);
      initialContext = urlParams.get("context") || undefined;
      if (initialContext) {
        const formattedContext =
          AutoContextService.formatContext(initialContext);

        this.updateTitle(formattedContext);

        return Promise.resolve(formattedContext);
      }
    }

    const storedOrNewModel =
      (await this.contextRepository.tryGet()) || new ContextModel();

    return Promise.resolve(storedOrNewModel.context);
  }

  private updateContextInQueryParameter(context: string) {
    /* istanbul ignore if */ // TODO: Test with mocking window.history.replaceState?
    if (!BrowserOrNode.isNode()) {
      // TODO: Sync context also in title so it is visible in tab. keep brainsupporter at the end. Rename function

      this.updateTitle(context);

      if (window.history.replaceState) {
        //prevents browser from storing history with each change:
        const url = new URL(location.href);
        if (context) {
          url.searchParams.set("context", context);
        } else {
          url.searchParams.delete("context");
        }
        // Replace url without reloading
        window.history.replaceState({}, "Brainsupporter", url);
      }
    }
  }

  /* istanbul ignore next */ // TODO: Test with spying document.title?
  private updateTitle(context: string) {
    if (context) {
      document.title = "@" + TextUtils.capitalizeFirstLetter(context);
    } else {
      document.title = "Brainsupporter";
    }
  }
}
