import * as Uuid from "uuid";

import { IDomainModel } from "./IDomainModel";
import { Validate } from "../validators/Validate";
import { EnvironmentUtils } from "../../util/EnvironmentUtils";

/* istanbul ignore next */ // TODO: Test
export class UserConfigModel implements IDomainModel {
  // Put all user config which should be encryptred here. Unecrypted data should go to UserModel
  public readonly uuid: string = Uuid.v4();
  public readonly modelVersion = 1;
  public static UserConfigModelType = "UserConfigModel";
  public readonly __type: string = UserConfigModel.UserConfigModelType;

  // Default off for production and on for others.
  public readonly enableLogging: boolean = !EnvironmentUtils.isProduction();

  public static configProperties = ["enableLogging"];

  protected static expectedProperties = [
    "modelVersion",
    "__type",
    "uuid",
  ].concat(UserConfigModel.configProperties);

  constructor(init?: Partial<UserConfigModel>) {
    Object.assign(this, init);
  }

  protected validateExpectedProperties() {
    Validate.expectedProperties(this, UserConfigModel.expectedProperties);
  }

  public validate() {
    this.validateExpectedProperties();
    Validate.Uuidv4(this.uuid);
    Validate.number(this.modelVersion);
    Validate.stringNotEmpty(this.__type);
    Validate.boolean(this.enableLogging);
  }
}
