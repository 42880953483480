import { DependencyInjectionUtils } from "../../../util/DependencyInjectionUtils";
import { AutoCompleteService } from "../../AutoCompleteService";
import { AutoContextService } from "../../AutoContextService";
import { ContextService } from "../../ContextService";
import { IExecutableBotCommand } from "../IBotCommand";
import { IBotCommandResult } from "../IBotCommandResult";

export class SetContextBotCommand implements IExecutableBotCommand {
  public static staticCommandName = "sc";

  public readonly commandName = SetContextBotCommand.staticCommandName;
  public readonly description =
    "Set context to filter tasks and automatically apply a context";
  public readonly smartButtonText = "Set context";
  public readonly argumentDescription = "context";

  constructor(
    private readonly autoCompleteService: AutoCompleteService,
    private readonly autoContextService: AutoContextService,
    private readonly contextService: ContextService,
  ) {
    DependencyInjectionUtils.validateDependenciesDefined(arguments);
  }

  public async execute(context: string): Promise<IBotCommandResult> {
    const processedContext = await this.autoContextService.setContext(
      context.trim(),
    );

    const feedback =
      processedContext !== ""
        ? "Context set to '" + processedContext + "'"
        : "Context cleared";

    return {
      commandName: this.commandName,
      feedback,
      returnParams: processedContext,
    };
  }

  // Can't test this in integration
  /* istanbul ignore next */
  public async getAutoCompleteKeywords(
    commandInput: string,
  ): Promise<string[]> {
    // TODO: Bug Autocomplete should only do one argument See also ConfigBotCommand
    const keywords = await this.contextService.getAutoCompleteContexts();

    return this.autoCompleteService.getAutoCompleteKeywords(
      commandInput,
      keywords,
    );
  }
}
