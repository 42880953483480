import { ArrayUtils } from "../util/ArrayUtils";
import { DateTimeUtils } from "../util/DateTimeUtils";
import { DependencyInjectionUtils } from "../util/DependencyInjectionUtils";
import { TaskService } from "./TaskService";

export class ContextService {
  constructor(private readonly taskService: TaskService) {
    DependencyInjectionUtils.validateDependenciesDefined(arguments);
  }

  public async getContextsFromActiveTasks(): Promise<string[]> {
    const activeTasks = await this.taskService.getUncompletedTasks();
    const allContexts = activeTasks.map((t) => t.contexts).flat();
    const distinctContexts = Array.from(new Set(allContexts)).sort();
    return distinctContexts;
  }

  /* istanbul ignore next */ // TODO: Integrationtest
  public async getRecentCompletedContexts(): Promise<string[]> {
    const allTasks = await this.taskService.getTasks();
    // TODO: Make 7 user configurable
    // TODO: Finish feature. turn off for now by setting it to 0. Make it user configurable.
    // How to deal with accidental contexts? Or should we do undo / delete first?
    // Uncomment test, serach for "getRecentCompletedContexts" and "lc shows contexts of recently completed tasks"
    const secondsInDays = 60 * 60 * 24 * 0; // Get all contexts up to x days ago:
    const lastMonth = DateTimeUtils.getEpochOffSetFromNow(secondsInDays);
    const recentCompletedTasks = allTasks.filter(
      (t) => t.completionDateTime && t.completionDateTime > lastMonth,
    );

    const recentCompletedOrActiveContexts = recentCompletedTasks
      .map((t) => t.contexts)
      .flat();

    const activeContexts = await this.getContextsFromActiveTasks();

    const recentCompletedContexts = ArrayUtils.difference(
      recentCompletedOrActiveContexts,
      activeContexts,
    );

    const distinctContexts = Array.from(
      new Set(recentCompletedContexts),
    ).sort();

    return distinctContexts;
  }

  public async getAutoCompleteContexts(
    onlyWithPrefix = false,
  ): Promise<string[]> {
    const activeContexts = (await this.getContextsFromActiveTasks()).sort();
    const recentContexts = (await this.getRecentCompletedContexts()).sort();

    const contexts = activeContexts.concat(recentContexts);

    const contextsWithAtSign = ContextService.addAtSign(contexts);
    if (onlyWithPrefix) {
      return contextsWithAtSign;
    }
    return contexts.concat(contextsWithAtSign);
  }

  public static addAtSign(contexts: string[]) {
    return contexts.map((c) => {
      return "@" + c;
    });
  }

  /* istanbul ignore next*/ // TODO: Test
  // TODO: Merge? with formatContext and move to ContextService
  public static normalizeContext(context: string | null): string {
    if (!context) {
      context = "";
    }

    context = context.toLocaleLowerCase().trim();

    if (context.startsWith("@")) {
      context = context.substring(1);
    }

    return context;
  }
}
