import { Component } from "react";
import "./App.css";
import Webconsole from "./components/Webconsole/Webconsole";
import ErrorNotification from "./components/ErrorNotification/ErrorNotification";
import EncryptionPasswordInput from "./components/EncryptionPasswordInput/EncryptionPasswordInput";
import { WebCompositionRoot } from "./WebCompositionRoot";
import Loader from "./components/Loader/Loader";
import NewEncryptionPasswordInput from "./components/NewEncryptionPasswordInput/NewEncryptionPasswordInput";
import { EmailHelper } from "brainsupporter-core/lib/util/EmailHelper";
import Footer from "./components/Footer/Footer";
import { EnvironmentUtils } from "brainsupporter-core/lib/util/EnvironmentUtils";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsightsService } from "./applicationInsights/ApplicationInsightsService";
import { EncryptionService } from "brainsupporter-core/lib/domain/EncryptionService";
import { FunctionalError } from "brainsupporter-core/lib/domain/errors/FunctionalError";
import React from "react";
import { UserModel } from "brainsupporter-core/lib/domain/domainModels/UserModel";
import { ConcurrencyUtils } from "brainsupporter-core/lib/util/ConcurrencyUtils";
import { Topics } from "brainsupporter-core/lib/domain/pubsub/Topics";
import { PagedApiRepository } from "brainsupporter-core/lib/repository/PagedApiRepository";

// TODO: Bug: With a window open and a new version installed it could give an empty screen. In devtools it can be seen
// the js of the previous version was loaded (with a different hash.) It returns a 404. example: static/js/main.093a2e92.js
// Not exactly sure how to reproduce. Solution update via progressive web app?

class AppState {
  errorMessages: string[] = [];
  email = "";
  decrypted = false;
  loading = 0; // nr of loading tasks running
  syncing = false;
  userExists = false;
  loggedIn: boolean | null = null; // loggedIn null means we don't know yet
  initialLoadPerformanceEnd = 0;
}

/* istanbul ignore next */ // Coverage can be improved
class App extends Component<unknown, AppState> {
  private root: WebCompositionRoot;
  private alreadyMounted = false;

  private static UNSUBSCRIBE_GROUP = "App";

  private static authenticatedUserUrl = "api/authenticatedUser";
  private static logoutUrl = "api/logout";

  constructor(props: unknown) {
    super(props);
    this.root = new WebCompositionRoot();

    this.state = new AppState();

    this.root.LogService.subscribeLogs(async (msg) => void this.showError(msg));

    // TODO: No subscriptions in the constructor. should subscribe on componentDidMount and unsubscribe on componentWillUnmount
    // These are not logged but only shown as error.
    this.root.Notifier.subscribe(
      // TODO: We should do something with the severity and colors as everything is red now
      FunctionalError.TOPIC,
      async (msg) => void this.showError(msg),
    );
  }

  private visibilityChangeListener = async (): Promise<void> => {
    // TODO: Now we have a longer session we don't need to check the server every time.
    // Instead do it once every day (so remember the time last checked).
    if (!document.hidden) {
      await this.checkAuthenticatedUser(); // TODO-RAS: No longer needed? Replace by a generic 401 detection
      // TODO: Fix bugs before enabling REFRESH_IF_NEEDED below on visibilityChanged
      // BUG: Error when returning to tab when not authenticated
      // BUG: Prevent syncing when changing password
      // BUG: Prevent syncing when entering initial password (sync won't do anyhting here so not really a bug but prevent anyway)
      // BUG: Scary things happen when the sync starts during import
      // BUG: Prevent sync as a precaution during export (export does a sync, so right after that)
      // BUG: Is it possible to start a sync while already syncing? Prevent this from happening
      // BUG: Wait until sync (if any) is complete before changing password
      // BUG: Consider sync during logout. We erase data while syncing?
      // void this.root.Notifier.publish(Topics.REFRESH_IF_NEEDED);
      Webconsole.focusToCommand();
    }
  };

  override async componentDidMount() {
    document.addEventListener("visibilitychange", () =>
      this.visibilityChangeListener(),
    );

    this.root.Notifier.subscribe(
      Topics.SYNCING,
      async (syncing: boolean) => this.setState({ syncing: syncing }),
      App.UNSUBSCRIBE_GROUP,
    );

    if (this.alreadyMounted) return;
    this.alreadyMounted = true;

    await this.executeAction(async () => {
      Webconsole.focusToCommand();

      this.setEnvironmentColors();

      const { userModel, email } = await this.checkAuthenticatedUser();

      const password =
        await this.root.EncryptionPasswordService.getEncryptionPassword(email);

      if (password) {
        // Warm the cache for the encryption key
        void this.root.EncryptionPasswordService.getDataEncryptionKey(
          userModel,
        );
      }

      if (!(await this.validateLogin(userModel, email, password))) {
        return;
      }

      await this.initializeApp(email);
    });
  }

  private async initializeApp(email: string) {
    const accountHash = EncryptionService.computeMD5(email);
    ApplicationInsightsService.initializeTelemetry(accountHash, this.root);

    // Password is in local storage
    await this.root.EventStore.replayEvents();
    this.root.EventStore.startSavingEvents();

    this.setState({
      decrypted: true,
      userExists: true,
      initialLoadPerformanceEnd: performance.now(),
    });

    this.refreshData();
  }

  override async componentWillUnmount() {
    document.removeEventListener(
      "visibilitychange",
      this.visibilityChangeListener,
    );

    this.root.Notifier.unsubscribeGroup(App.UNSUBSCRIBE_GROUP);
  }

  private refreshData() {
    void ConcurrencyUtils.runWhenIdle(async () => {
      // Update the userConfig from the server (if any) so we can apply things like logging
      await this.root.UserConfigRepository.tryGet();
      await this.root.Notifier.publish(Topics.CONFIG_CHANGED);

      // Refesh events when timestamp out of date
      await this.root.Notifier.publish(Topics.REFRESH_IF_NEEDED);
    });
  }

  private validateLogin = async (
    userModel: UserModel | undefined,
    email: string,
    password: string | null,
  ): Promise<boolean> => {
    const result = await this.executeAction<boolean>(async () => {
      if (email) {
        EmailHelper.validateEmailFormat(email);
      } else {
        return false;
      }

      const encryptionPasswordIsCached =
        this.root.EncryptionPasswordService.isEncryptionPasswordCached(email);
      const userExists = !!userModel;

      if (!userExists || !encryptionPasswordIsCached || password === null) {
        this.setState({
          decrypted: false,
          userExists: userExists,
          email: email,
          loggedIn: !!email,
        });
        return false;
      }

      try {
        await this.root.EncryptionPasswordService.verifyExistingEncryptionPassword(
          email,
          password as string,
          userModel,
        );
      } catch (e: unknown) {
        const error = e as Error;
        if (error.message === "Encryption password is incorrect.") {
          this.setState({
            decrypted: false,
            userExists: true,
          });
          return false;
        } else {
          throw error;
        }
      }

      return true;
    });

    if (result === undefined) {
      return false;
    }
    return result;
  };

  private showError = async (errorMessage: string) => {
    // add error
    this.setState((oldState) => ({
      errorMessages: [...oldState.errorMessages, errorMessage],
    }));

    // wait 20 seconds and then remove the error message
    await ConcurrencyUtils.wait(20 * 1000);

    // remove error
    this.setState((oldState) => ({
      errorMessages: (() => this.removeErrorMessage(oldState, 0))(),
    }));
  };

  private async checkAuthenticatedUser() {
    let email = "";
    let userModel: UserModel | undefined;
    try {
      // Check for existing login session
      userModel = await this.root.UserRepository.tryGet();
    } catch (e: unknown) {
      // not logged in
    }

    if (!userModel) {
      email = await this.getEmailFromOAuth();
      if (email) {
        // New user
        this.setState({
          userExists: false, // TODO: We set userExists manually and by this.root.UserService.userExists. Can we simplify?
        });
      }
    } else {
      email = userModel.email;
    }

    this.root.ConfigurationManager.setEmail(email);
    this.setState({
      loggedIn: email !== "",
      email: email,
    });

    return { userModel, email };
  }

  private async getEmailFromOAuth(): Promise<string> {
    const response = await fetch("/.auth/me");
    if (response.status !== 200) {
      // not logged in
      return "";
    }

    const payload = await response.json();
    const { clientPrincipal } = payload;
    let email;
    if (clientPrincipal !== null) {
      email = clientPrincipal.userDetails;
    } else {
      email = "";
    }

    return email;
  }

  private removeErrorMessage(oldState: AppState, index: number): string[] {
    const newState = oldState.errorMessages.slice();
    newState.splice(index, 1);
    return newState;
  }

  private encryptionPasswordSubmitted = async (
    password: string,
  ): Promise<void> => {
    await this.executeAction(async () => {
      this.root.EncryptionKeySecureCache.clearCache();

      const user = await this.root.UserRepository.get();
      await this.root.EncryptionPasswordService.verifyExistingEncryptionPassword(
        this.state.email,
        password,
        user,
      );
      await this.initializeApp(user.email);
    });
  };

  private newUserEncryptionPasswordSubmitted = async (
    password: string,
  ): Promise<void> => {
    await this.executeAction(async () => {
      await this.root.UserService.createNewUser(this.state.email, password);
      await this.initializeApp(this.state.email);
    });
  };

  // Do action, show errors and set spinner
  private async executeAction<T>(
    action: () => Promise<T>,
  ): Promise<T | undefined> {
    this.setLoading(true);

    try {
      return await action();
    } catch (e: unknown) {
      const error = e as Error;
      await this.showError(error.message);
    } finally {
      this.setLoading(false);
    }
  }

  private setLoading = (loading: boolean) => {
    const loadingDelta = loading ? 1 : -1;

    this.setState((prevState) => ({
      loading: prevState.loading - loadingDelta,
    }));
  };

  private IsLoggedIn() {
    return this.state.email;
  }

  private async logout() {
    await this.root.Notifier.publish(Topics.LOG_OUT); // Handles securecache of password and cached repositories of events and usersettings
    PagedApiRepository.clearCacheCounts();
    await this.root.ContextRepository.delete(); // Context gone, is in indexdb
    await this.root.RestUtils.post(App.logoutUrl); // Deletes session cookie
    window.location.href = "/";
  }

  private setEnvironmentColors() {
    if (!EnvironmentUtils.isProduction()) {
      let color: string;
      if (EnvironmentUtils.isCanary()) {
        color = "lightyellow";
      } else {
        color = "lightblue";
      }

      document.getElementById("topheader")!.style.backgroundColor = color;
      document.getElementById("topdivider")!.style.backgroundColor = color;
    }
  }

  override render() {
    let mainComponent;

    if (this.state.loggedIn === false) {
      mainComponent = (
        <div>
          <p>
            Brainsupporter is a task manager that tries it's best to help you.
          </p>
          <p>Login with a google account get started.</p>
          <p>
            Join the{" "}
            <a
              href="https://chat.whatsapp.com/K8qCASCDUG5HmnVnsNNtPw"
              target="_blank"
            >
              whatsapp group
            </a>{" "}
            for questions or feedback.
          </p>
        </div>
      );
    } else if (this.state.decrypted) {
      mainComponent = (
        <Webconsole errorMessage={this.showError} root={this.root} />
      );
    } else if (
      this.IsLoggedIn() &&
      this.state.userExists &&
      !this.state.loading
    ) {
      mainComponent = (
        <EncryptionPasswordInput
          onPasswordSubmitted={this.encryptionPasswordSubmitted}
        />
      );
    } else if (this.IsLoggedIn() && !this.state.loading) {
      // TODO: Bug: If an error happens during initial load and we wait until loading completes we show NewEncryptionPasswordInput
      // which should not happen.
      mainComponent = (
        <NewEncryptionPasswordInput
          onPasswordSubmitted={this.newUserEncryptionPasswordSubmitted}
        />
      );
    } else {
      mainComponent = null;
    }

    let loginLogout = null;
    if (this.state.loggedIn === null) {
      loginLogout = "";
    } else if (this.state.loggedIn) {
      loginLogout = (
        <a
          href="#"
          onClick={async () => {
            await this.logout();
          }}
        >
          Log out
        </a>
      );
    } else {
      loginLogout = <a href="/.auth/login/google">Login with Google</a>;
    }

    return (
      // TODO: remove React.StrictMode here and apply at top level when it is properly implemented
      <div className="App">
        <React.StrictMode>
          {/* TODO: Skip AppInsightsContext when turned of. */}
          <AppInsightsContext.Provider
            value={ApplicationInsightsService.reactPlugin}
          >
            <nav className="menu" id="topheader">
              <div>{loginLogout}</div>
              <div className="user">
                <a>{this.state.email}</a>
              </div>
              <div className="sync">{this.state.syncing ? "🔄" : ""}</div>{" "}
              {/* TODO: Unittest sync icon */}
              {/* TODO: User should be a span and after nav keep all on 1 line */}
            </nav>
            <div id="topdivider">
              <hr />
            </div>
            <Loader loading={!!this.state.loading} />
            <ErrorNotification
              errorMessages={this.state.errorMessages}
              onChange={(errorMessages: string[]) =>
                this.setState({ errorMessages })
              }
            />
            {mainComponent}
            <Footer
              initialLoadPerformance={
                this.state.initialLoadPerformanceEnd // Start is at 0;
              }
            />
          </AppInsightsContext.Provider>
        </React.StrictMode>
      </div>
    );
  }
}

export default App;
