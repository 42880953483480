import { LogService } from "brainsupporter-core/lib/logging/LogService";

import { ApplicationInsightsService } from "./ApplicationInsightsService";
import { SeverityLevel } from "@microsoft/applicationinsights-web";

/* istanbul ignore next */ // Coverage can be improved
export class ApplicationInsightsLogService extends LogService {
  protected override logMessageSuffix = "";

  public override async logDebug(error: Error): Promise<void> {
    if (ApplicationInsightsService.enableLogging) {
      ApplicationInsightsService.appInsights.trackException({
        exception: error,
        severityLevel: SeverityLevel.Verbose,
      });
    }
    await super.logDebug(error);
  }

  public override async logDebugMessage(message: string): Promise<void> {
    if (ApplicationInsightsService.enableLogging) {
      ApplicationInsightsService.appInsights.trackTrace({
        message: message,
        severityLevel: SeverityLevel.Verbose,
      });
    }

    await super.logDebugMessage(message);
  }

  public override async logInfo(error: Error): Promise<void> {
    if (ApplicationInsightsService.enableLogging) {
      ApplicationInsightsService.appInsights.trackException({
        exception: error,
        severityLevel: SeverityLevel.Information,
      });
    }
    await super.logInfo(error);
  }

  public override async logInfoMessage(message: string): Promise<void> {
    if (ApplicationInsightsService.enableLogging) {
      ApplicationInsightsService.appInsights.trackTrace({
        message: message,
        severityLevel: SeverityLevel.Information,
      });
    }
    await super.logInfoMessage(message);
  }

  public override async logWarning(error: Error): Promise<void> {
    if (ApplicationInsightsService.enableLogging) {
      ApplicationInsightsService.appInsights.trackException({
        exception: error,
        severityLevel: SeverityLevel.Warning,
      });
    }
    await super.logWarning(error);
  }

  public override async logWarningMessage(message: string): Promise<void> {
    if (ApplicationInsightsService.enableLogging) {
      ApplicationInsightsService.appInsights.trackTrace({
        message: message,
        severityLevel: SeverityLevel.Warning,
      });
    }

    await super.logWarningMessage(message);
  }

  public override async logError(error: Error): Promise<void> {
    if (ApplicationInsightsService.enableLogging) {
      ApplicationInsightsService.appInsights.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
    await super.logError(error);
  }

  public override async logErrorMessage(message: string): Promise<void> {
    if (ApplicationInsightsService.enableLogging) {
      ApplicationInsightsService.appInsights.trackTrace({
        message: message,
        severityLevel: SeverityLevel.Error,
      });
    }

    await super.logErrorMessage(message);
  }
}
