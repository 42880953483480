import { IIdentifiable } from "../domain/domainModels/IIdentifiable";
import { IRepository } from "../domain/IRepository";
import { Notifier } from "../domain/pubsub/Notifier";
import { Topics } from "../domain/pubsub/Topics";
import { timestamp } from "../domain/Types";
import { LogService } from "../logging/LogService";
import { DateTimeUtils } from "../util/DateTimeUtils";
import { DependencyInjectionUtils } from "../util/DependencyInjectionUtils";
import { AbstractRepository } from "./AbstractRepository";

export class CachedRepository<T extends IIdentifiable>
  extends AbstractRepository<T>
  implements IRepository<T>
{
  private lastDataRepoTimestamp: timestamp = DateTimeUtils.zeroTimestamp;

  constructor(
    private readonly logService: LogService,
    private readonly cacheRepo: IRepository<T>,
    private readonly dataRepo: IRepository<T>,
    private readonly notifier: Notifier<T>,
    private readonly updates?: () => Promise<void>,
    private initializeFromCache: boolean = false,
  ) {
    super();
    DependencyInjectionUtils.validateDependenciesDefined(arguments);

    /* istanbul ignore next */ // TODO: No integration coverage yet.  Can we write a logout integration test? Maybe transform code in app.tsx to a logout command
    this.notifier.subscribe(Topics.LOG_OUT, async () => {
      await this.cacheRepo.deleteAll();
      this.lastDataRepoTimestamp = DateTimeUtils.zeroTimestamp;
    });

    if (this.updates) {
      this.notifier.subscribe(Topics.REFRESH_IF_NEEDED, () =>
        this.refreshIfNeeded(),
      );
    }
  }

  private async refreshIfNeeded() {
    if (await this.isInvalidTimestamp()) {
      await this.refreshData();
    }
  }

  public async getAll(): Promise<readonly T[]> {
    /* istanbul ignore next */ // TODO: Test
    if (this.updates && this.initializeFromCache) {
      this.initializeFromCache = false;
      // TODO: This leads to ordering issues in events. As we can put events on old or empty state, sequence numbers are not the highest
      return this.tryGetAllFromCache();
    }

    const dataRepoTimestamp = await this.dataRepo.getTimestamp();
    /* istanbul ignore if */ // TODO: Write integration test
    if (dataRepoTimestamp === this.lastDataRepoTimestamp) {
      return this.tryGetAllFromCache();
    }

    return this.getAllFromData(dataRepoTimestamp);
  }

  private async tryGetAllFromCache() {
    try {
      return await this.cacheRepo.getAll();
    } catch (error) {
      /* istanbul ignore next */ // No error tested in integration
      {
        await this.logService.logWarningMessage(
          "Error during initializing from cache. Trying to get data from the server",
        );
        await this.logService.logWarning(error as Error);
        return this.getAllFromData();
      }
    }
  }

  private async getAllFromData(dataRepoTimestamp?: timestamp) {
    /* istanbul ignore next */ // dataRepoTimestamp undefined not tested during integration
    this.lastDataRepoTimestamp =
      dataRepoTimestamp ?? (await this.dataRepo.getTimestamp());

    const all = await this.dataRepo.getAll();
    await this.cacheRepo.deleteAll();
    await this.cacheRepo.import(all);
    return all;
  }

  /* istanbul ignore next */ //Not used during integration
  public override async tryGetByUuid(uuid: string): Promise<T | undefined> {
    if (await this.isInvalidTimestamp()) {
      return this.dataRepo.tryGetByUuid(uuid);
    }

    return this.cacheRepo.tryGetByUuid(uuid);
  }

  public async save(model: T): Promise<timestamp> {
    const timestamp = await this.updateTimeStamp(() =>
      this.dataRepo.save(model),
    );
    await this.cacheRepo.save(model);
    return timestamp;
  }

  public async deleteAll(): Promise<timestamp> {
    await this.cacheRepo.deleteAll();
    await this.dataRepo.deleteAll();
    this.lastDataRepoTimestamp = DateTimeUtils.zeroTimestamp;
    return this.lastDataRepoTimestamp;
  }

  /* istanbul ignore next */ // Not used as getTimestamp is for caching
  public async getTimestamp(): Promise<timestamp> {
    return this.dataRepo.getTimestamp();
  }

  // hard to test during integration
  /* istanbul ignore next */
  public async delete(uuid: string): Promise<timestamp> {
    const timestamp = await this.updateTimeStamp(() =>
      this.dataRepo.delete(uuid),
    );
    await this.cacheRepo.delete(uuid);
    return timestamp;
  }

  public async import(models: readonly T[]): Promise<timestamp> {
    const timestamp = await this.dataRepo.import(models);
    await this.cacheRepo.import(models);
    return timestamp;
  }

  private async updateTimeStamp(
    repoAction: () => Promise<timestamp>,
  ): Promise<timestamp> {
    const isInvalidTimestamp = await this.isInvalidTimestamp();

    /* istanbul ignore if */ // TODO: Write integration test
    if (isInvalidTimestamp) {
      await this.refreshData(); // Timestamp changed serverside
    }

    const newTimestamp = await repoAction();

    /* istanbul ignore if */ // TODO: Write integration test
    if (this.lastDataRepoTimestamp !== DateTimeUtils.zeroTimestamp) {
      // refreshData updated data. So we are (probably) up to date
      this.lastDataRepoTimestamp = newTimestamp;
    }

    return this.lastDataRepoTimestamp;
  }

  private async isInvalidTimestamp(): Promise<boolean> {
    const repoTimestamp = await this.dataRepo.getTimestamp();
    // repoTimestamp is a number when the data is stored on azure as that code runs on node.
    /* istanbul ignore next */ // TODO: Test
    const isInvalidTimestamp =
      repoTimestamp === DateTimeUtils.zeroTimestamp ||
      this.lastDataRepoTimestamp !== repoTimestamp;

    if (isInvalidTimestamp) {
      this.lastDataRepoTimestamp = DateTimeUtils.zeroTimestamp;
    }

    return isInvalidTimestamp;
  }

  /* istanbul ignore next */ // TODO: Test
  private async refreshData() {
    if (this.updates) {
      await this.updates();
    }
  }
}
