import { IModel } from "../../domain/domainModels/IModel";
import { UserConfigModel } from "../../domain/domainModels/UserConfigModel";
import { Mutable } from "../../domain/Types";
import { ModelCloner } from "../../util/ModelCloner";
import { IMigrator } from "../IMigrator";

export class UserConfigModelMigrator implements IMigrator<UserConfigModel> {
  public migrate(
    userConfigModels: readonly IModel[],
  ): readonly UserConfigModel[] {
    const results: UserConfigModel[] = [];

    for (const userConfigModel of userConfigModels) {
      const clonedUserConfigModel = ModelCloner.cloneToType(
        userConfigModel,
        UserConfigModel.UserConfigModelType,
      ) as Mutable<UserConfigModel>;

      // Add migrations here

      clonedUserConfigModel.validate();
      results.push(clonedUserConfigModel as UserConfigModel);
    }

    return results;
  }
}
