import { ProjectTxtRepository } from "../../../repository/ProjectTxtRepository";
import { TodoTxtRepository } from "../../../repository/TodoTxtRepository";
import { DateTimeUtils } from "../../../util/DateTimeUtils";
import { DependencyInjectionUtils } from "../../../util/DependencyInjectionUtils";
import { EnvironmentUtils } from "../../../util/EnvironmentUtils";
import { FileInMemoryHelper } from "../../../util/FileInMemoryHelper";
import { ZipUtils } from "../../../util/ZipUtils";
import { ProjectModel } from "../../domainModels/ProjectModel";
import { TaskModel } from "../../domainModels/TaskModel";
import { DomainEventModel } from "../../events/DomainEventModel";
import { IReadOnlyRepository } from "../../IReadonlyRepository";
import { Notifier } from "../../pubsub/Notifier";
import { Topics } from "../../pubsub/Topics";
import { IExecutableBotCommand } from "../IBotCommand";
import { IBotCommandResult } from "../IBotCommandResult";

export class ExportBotQuery implements IExecutableBotCommand {
  public static domainEventsExportFilePath = "domainevents";
  public static todoTxtTaskExportFilePath = "todotxt/todo.txt";
  public static todoTxtProjectExportFilePath = "todotxt/projects.txt";

  public static staticCommandName = "export";

  public readonly commandName = ExportBotQuery.staticCommandName;
  public readonly description = "export data to a zipfile";
  public readonly smartButtonText = "Export";

  constructor(
    private readonly domainEventRepo: IReadOnlyRepository<DomainEventModel>,
    private readonly taskView: IReadOnlyRepository<TaskModel>,
    private readonly inputProjectRepository: IReadOnlyRepository<ProjectModel>,
    private readonly notifier: Notifier,
  ) {
    DependencyInjectionUtils.validateDependenciesDefined(arguments);
  }

  // TODO: Userconfig ook in import
  public async execute(): Promise<IBotCommandResult> {
    // When we support offline print a warning the data might not be up to date as we can't sync
    await this.notifier.publish(Topics.REFRESH_IF_NEEDED);
    const todoTxtFileInMemory = await this.GenerateTodoTxtFile();
    const todoTxtProjectFileInMemory = await this.GenerateTodoTxtProjectFile();
    const events = await this.domainEventRepo.getAll();

    const zipData = await this.generateExportZip(
      await todoTxtFileInMemory.readFile(),
      await todoTxtProjectFileInMemory.readFile(),
      events,
    );

    const epoch = DateTimeUtils.getEpoch();
    const enviromentPrefix = this.getEnviromentPrefix();
    const zipFileName = enviromentPrefix + "export." + epoch + ".zip";

    const feedback = "exported file " + zipFileName;

    return {
      commandName: this.commandName,
      feedback,
      returnParams: {
        zipData,
        zipFileName,
      },
    };
  }

  // Can't test this in integration
  /* istanbul ignore next */
  public async getAutoCompleteKeywords(
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    commandInput: string,
  ): Promise<string[]> {
    return Promise.resolve([]);
  }

  private async GenerateTodoTxtFile() {
    const todoTxtFileInMemory = new FileInMemoryHelper();
    await todoTxtFileInMemory.saveTextFile("");
    const todoTxtRepository = new TodoTxtRepository(todoTxtFileInMemory);

    const tasks = await this.taskView.getAll();
    await todoTxtRepository.import(tasks);

    return todoTxtFileInMemory;
  }

  private async GenerateTodoTxtProjectFile() {
    const projectTxtFileInMemory = new FileInMemoryHelper();
    await projectTxtFileInMemory.saveTextFile("");
    const projectTxtRepository = new ProjectTxtRepository(
      projectTxtFileInMemory,
    );

    const projects = await this.inputProjectRepository.getAll();
    await projectTxtRepository.import(projects);

    return projectTxtFileInMemory;
  }

  private async generateExportZip(
    todoTxtContent: string,
    projectTxtContent: string,
    events: readonly DomainEventModel[],
  ) {
    const zip = ZipUtils.newZip();
    zip.file(ExportBotQuery.todoTxtTaskExportFilePath, todoTxtContent);
    zip.file(ExportBotQuery.todoTxtProjectExportFilePath, projectTxtContent);

    for (const event of events) {
      const filePathAndName =
        ExportBotQuery.domainEventsExportFilePath + "/" + event.uuid + ".json";
      zip.file(filePathAndName, JSON.stringify(event));
    }

    return ZipUtils.generateAsync(zip);
  }

  private getEnviromentPrefix(): string {
    /* istanbul ignore next */ // Coverage changes with enviroment
    if (EnvironmentUtils.isProduction()) {
      return "";
    } else {
      return EnvironmentUtils.getEnvironment() + ".";
    }
  }
}
