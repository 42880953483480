import { LogService } from "../../logging/LogService";
import { EnvironmentUtils } from "../../util/EnvironmentUtils";

export class ViewUtil {
  public static async SkipOnError(func: () => Promise<void>): Promise<void> {
    try {
      await func();
    } catch (error) /* istanbul ignore next : No integration test for this */ {
      // Something went wrong updating the view. Skip this and continue
      if (!EnvironmentUtils.isProduction()) {
        // TODO: Log the error but not visible as error in the UI. // Can we log only recent errors by looking at creation date of events?
        await LogService.getInstance().logWarningMessage(
          "Non fatal error updating views. Showing error in non production enviroment",
        );
        await LogService.getInstance().logWarning(error as Error);
      }
    }
  }
}
