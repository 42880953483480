import { IModel } from "../../domain/domainModels/IModel";
import { UserModel } from "../../domain/domainModels/UserModel";
import { Mutable } from "../../domain/Types";
import { ModelCloner } from "../../util/ModelCloner";
import { IMigrator } from "../IMigrator";
import { addUserModelType } from "./scripts/20240729-addUserModelType";

export class UserModelMigrator implements IMigrator<UserModel> {
  public migrate(userModels: readonly IModel[]): readonly UserModel[] {
    const results: UserModel[] = [];

    for (const userModel of userModels) {
      const clonedUserModel = ModelCloner.cloneToType(
        userModel,
        UserModel.UserModelType,
      ) as Mutable<UserModel>;
      addUserModelType(clonedUserModel);

      clonedUserModel.validate();
      results.push(clonedUserModel as UserModel);
    }

    return results;
  }
}
