import { SingleRepository } from "../../../repository/SingleRepository";
import { DependencyInjectionUtils } from "../../../util/DependencyInjectionUtils";
import { ModelCloner } from "../../../util/ModelCloner";
import { TextUtils } from "../../../util/TextUtils";
import { AutoCompleteService } from "../../AutoCompleteService";
import { IModel } from "../../domainModels/IModel";
import { UserConfigModel } from "../../domainModels/UserConfigModel";
import { Notifier } from "../../pubsub/Notifier";
import { Topics } from "../../pubsub/Topics";
import { Mutable } from "../../Types";
import { IExecutableBotCommand } from "../IBotCommand";
import { IBotCommandResult } from "../IBotCommandResult";

export class ConfigBotCommand implements IExecutableBotCommand {
  public static staticCommandName = "config";

  public readonly commandName = ConfigBotCommand.staticCommandName;
  public readonly description = "Read or change configuration";
  public readonly smartButtonText = "Config";
  public readonly argumentDescription =
    "\nconfig enableLogging [true|false (default)]";

  constructor(
    private readonly configRepository: SingleRepository<UserConfigModel>,
    private readonly autoCompleteService: AutoCompleteService,
    private readonly notifier: Notifier,
  ) {
    DependencyInjectionUtils.validateDependenciesDefined(arguments);
  }

  public async execute(input: string): Promise<IBotCommandResult> {
    let feedback = "";

    const config =
      (await this.configRepository.tryGet()) || new UserConfigModel();

    if (!input) {
      // Print out current settings
      for (const prop of UserConfigModel.configProperties) {
        feedback = prop + " " + (config as IModel)[prop];
      }
    } else {
      const parts = input.split(" ");
      const clonedModel = ModelCloner.clone(config) as Mutable<UserConfigModel>;

      // When extending this: Loop configProperties and generalize types
      if (
        parts.length === 2 &&
        TextUtils.compareIgnoreCase(parts[0], "enableLogging")
      ) {
        // This can be generalized to a more generic type checker using Validate later  when adding more
        if (TextUtils.compareIgnoreCase(parts[1], "true")) {
          clonedModel.enableLogging = true;
          feedback += "Setting " + parts[0] + " to true";
        } else if (TextUtils.compareIgnoreCase(parts[1], "false")) {
          clonedModel.enableLogging = false;
          feedback += "Setting " + parts[0] + " to false";
        } else {
          feedback += parts[1] + " should be true or false";
        }

        const updatedModel = clonedModel as UserConfigModel;
        await this.configRepository.save(updatedModel);
        await this.notifier.publish(Topics.CONFIG_CHANGED);
      } else {
        feedback += "didn't understand that";
      }
    }

    return {
      commandName: this.commandName,
      feedback,
    };
  }

  // Can't test this in integration
  /* istanbul ignore next */
  public async getAutoCompleteKeywords(
    commandInput: string,
  ): Promise<string[]> {
    // TODO: Bug Autocomplete should only do one argument See also SetContextCommand
    // TODO: Improve this with possible values like true false
    const keywords = UserConfigModel.configProperties;

    return this.autoCompleteService.getAutoCompleteKeywords(
      commandInput,
      keywords,
    );
  }
}
