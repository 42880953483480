import * as Uuid from "uuid";

import { DateTimeUtils } from "../../util/DateTimeUtils";
import { IDomainModel } from "../domainModels/IDomainModel";
import { epoch } from "../Types";
import { Validate } from "../validators/Validate";
import { INotifyTopic } from "../pubsub/INotifyTopic";

export abstract class DomainEventModel implements IDomainModel, INotifyTopic {
  public uuid: string = Uuid.v4();
  public abstract modelVersion: number;
  public abstract __type: string;

  public eventDateTime: epoch = DateTimeUtils.getEpoch();
  public sequenceNumber = 0; // Absolute ordering of events on the server

  public abstract eventName: string;

  protected static expectedProperties = [
    "uuid",
    "eventDateTime",
    "sequenceNumber",
  ];

  protected abstract validateExpectedProperties(): void;

  public validate() {
    Validate.Uuidv4(this.uuid);
    Validate.number(this.modelVersion);
    Validate.numberPositive(this.eventDateTime);
  }

  public getTopic(): string {
    return this.eventName;
  }
}
