import { Component } from "react";

// TODO: Check styling on really long contexts
import "./ContextChooser.css";

import { AbstractCompositionRoot } from "brainsupporter-core/lib/domain/AbstractCompositionRoot";
import { BotService } from "brainsupporter-core/lib/domain/bot/BotService";
import { AutoContextService } from "brainsupporter-core/lib/domain/AutoContextService";
import Webconsole from "../Webconsole/Webconsole";
import { Notifier } from "brainsupporter-core/lib/domain/pubsub/Notifier";
import { Topics } from "brainsupporter-core/lib/domain/pubsub/Topics";
import { ContextService } from "brainsupporter-core/lib/domain/ContextService";

class ContextChooserState {
  currentContext = "";
  inputContext = "";
  contextList: string[] = [];
}

type ContextChooserProps = {
  root: AbstractCompositionRoot;
};

class ContextChooser extends Component<
  ContextChooserProps,
  ContextChooserState
> {
  private static UNSUBSCRIBE_GROUP = "ContextChooser";

  botService: BotService;
  contextService: ContextService;
  autoContextService: AutoContextService;
  notifier: Notifier;

  constructor(props: ContextChooserProps) {
    super(props);

    this.botService = this.props.root.BotService;
    this.contextService = this.props.root.ContextService;
    this.autoContextService = this.props.root.AutoContextService;
    this.notifier = this.props.root.Notifier;

    this.state = new ContextChooserState();
  }

  override async componentDidMount() {
    this.notifier.subscribe(
      Topics.UPDATE_UI,
      async () => {
        await this.updateContextUI();
      },
      ContextChooser.UNSUBSCRIBE_GROUP,
    );

    await this.updateContextUI();
  }

  override async componentWillUnmount() {
    this.notifier.unsubscribeGroup(ContextChooser.UNSUBSCRIBE_GROUP);
  }

  private async refreshContextList() {
    const activeContexts =
      await this.contextService.getContextsFromActiveTasks();
    let contextList: string[] = [" "]; // Add empty option to clear context
    contextList = contextList.concat(ContextService.addAtSign(activeContexts));

    const recentCompletedContexts =
      await this.contextService.getRecentCompletedContexts();
    /* istanbul ignore next */ // TODO: Fix commented out test
    if (recentCompletedContexts.length > 0) {
      contextList = contextList.concat(" "); // Add seperator for recent context (or clear context)
      contextList = contextList.concat(
        ContextService.addAtSign(recentCompletedContexts),
      );
    }

    this.setState({ contextList: contextList });
  }

  private async updateContextUI() {
    await this.refreshContextList();
    const autoContext = await this.autoContextService.getContext();
    const context = ContextService.normalizeContext(autoContext);
    this.setState({ currentContext: context });
  }

  private async setContextCommand(context: string) {
    await this.botService.sendText("sc " + context);
    // Updates the UI
    await this.notifier.publish(Topics.UPDATE_UI);
    await this.notifier.publish(Topics.CLEAR_FEEDBACK);
  }

  /* istanbul ignore next */ // Coverage can be improved
  handleChange = async (event: React.FormEvent<HTMLInputElement>) => {
    const context = event.currentTarget.value;
    this.setState({ inputContext: context });
  };

  /* istanbul ignore next */ // Coverage can be improved
  handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault(); // Stop reload
    this.setState({ inputContext: "" });

    const newContext = ContextService.normalizeContext(this.state.inputContext);
    await this.setContextCommand(newContext);

    Webconsole.focusToCommand();
  };

  /* istanbul ignore next */ // Coverage can be improved
  handleFocus = async () => {
    // Clear the input so there is no filtering
    this.setState({ inputContext: "" });
  };

  /* istanbul ignore next */ // Coverage can be improved
  handleMousedown = async () => {
    // Refresh before focus in order to avoid redraw
    await this.refreshContextList();
  };

  // LATER: Firefox behaves very different
  // LATER: Because of above and all the detailed implementation events, replace by https://react-select.com/ ?
  /* istanbul ignore next */ // Coverage can be improved
  handleSelect = async (event: React.SyntheticEvent) => {
    const list = this.state.contextList;
    const input = this.state.inputContext;

    let startsWith = 0;
    for (const item of list) {
      if (item.startsWith(input)) {
        startsWith++;
      }
    }

    let equals = 0;
    for (const item of list) {
      if (item === input) {
        equals++;
      }
    }

    // Don't submit if a longer match could work, let the user do it
    if (equals === 1 && startsWith === 1) {
      await this.handleSubmit(event);
    }
  };

  override render() {
    return (
      <form className="contextChooserForm" onSubmit={this.handleSubmit}>
        <label htmlFor="context">Context @{this.state.currentContext}</label>
        <input
          type="text"
          id="contextList"
          className="contextList"
          data-testid="contextList"
          name="context"
          value={this.state.inputContext}
          onChange={this.handleChange}
          list="contextDataList"
          autoComplete="off"
          onFocus={this.handleFocus}
          onMouseDown={this.handleMousedown}
          onSelect={this.handleSelect}
        />
        <datalist id="contextDataList" data-testid="contextDataList">
          {this.state.contextList.map((option, idx) => (
            <option key={option + idx} value={option}>
              {option}
            </option>
          ))}
        </datalist>
      </form>
    );
  }
}

export default ContextChooser;
